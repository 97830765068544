/*
All of our tile source information in one place.
*/

import * as constants from './constants.js';

// A function that builds a dynamic tile source object according to our usual naming conventions
function _buildDynamicTileSource(tilesource_id) {
    return {
        id: tilesource_id,
        url: `${constants.dynamicTilesBase}/${tilesource_id}/{z}/{x}/{y}.pbf`,
    };
}

const dynamicTileSource = {
    id: 'eroadmap', // can be anything; this is for us to keep track of sources and layers
    url: constants.dynamicTilesBase + '/eroadmapdata.demand/{z}/{x}/{y}.pbf',
};

const staticTileSource = {
    id: 'eroadmap_static',
    url: constants.staticTilesBase + '/demand/2030/{z}/{x}/{y}.pbf',
};

const hosting_capacity_detail = {
    id: 'eroadmapdata.hosting_capacity_combined',
    url:
        constants.dynamicTilesBase +
        '/eroadmapdata.hosting_capacity_combined/{z}/{x}/{y}.pbf',
};

const hosting_capacity_polygons = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_polygons'
);

// Commonwealth Edison (Illinois) needs to be shown using the next five layers;
// see https://github.com/eldang/evs2scale/issues/278 for details
const hosting_capacity_comed_township = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_comed_township'
);
const hosting_capacity_comed_grid_section = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_comed_grid_section'
);
const hosting_capacity_comed_grid_qtr_section = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_comed_grid_qtr_section'
);
const hosting_capacity_comed_grid_16th_section = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_comed_grid_16th_section'
);
const hosting_capacity_comed_circuits = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_comed_circuits'
);

// Baltimore Gas & Electric needs to be shown using the next 3 layers;
// see https://github.com/eldang/evs2scale/issues/281 for details
const hosting_capacity_bge_mile = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_bge_mile'
);
const hosting_capacity_bge_quarter_mile = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_bge_quarter_mile'
);
const hosting_capacity_bge_sixteenth_mile = _buildDynamicTileSource(
    'eroadmapdata.hosting_capacity_bge_sixteenth_mile'
);

// Back to the usual pattern
const ev_charging = _buildDynamicTileSource(
    'eroadmapdata.ev_charging_stations'
);

const justice40_static = {
    id: 'justice40',
    url: constants.staticTilesBase + '/justice40/{z}/{x}/{y}.pbf',
};

const justice40_dynamic = _buildDynamicTileSource('eroadmapdata.justice40');

const truck_stops = {
    id: 'jlaw_truck_stops_data_shapefi-7kpepn',
    url: 'mapbox://eroadmap.5g88mozp',
};

const utility_service_areas = {
    id: 'utility_service_areas-1mmwjt',
    url: 'mapbox://eroadmap.3jl96gjr',
};

export {
    dynamicTileSource,
    ev_charging,
    hosting_capacity_detail,
    hosting_capacity_polygons,
    hosting_capacity_bge_mile,
    hosting_capacity_bge_quarter_mile,
    hosting_capacity_bge_sixteenth_mile,
    hosting_capacity_comed_township,
    hosting_capacity_comed_grid_section,
    hosting_capacity_comed_grid_qtr_section,
    hosting_capacity_comed_grid_16th_section,
    hosting_capacity_comed_circuits,
    justice40_dynamic,
    justice40_static,
    staticTileSource,
    truck_stops,
    utility_service_areas,
};
